import { LinearProgress } from '@mui/material';
// import { Redirect, Route, Switch } from 'react-router-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { RouteItems } from '../constants';

const router = createBrowserRouter(RouteItems);

export default function Routes(): JSX.Element {
  return (
    <RouterProvider router={router} />
  );
}

import { Stack, useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import react from 'react'
import { Link } from 'react-router-dom';
interface SlickPageProps {
    link?: string;
    name?: string;
    img: string;
    backgroundColour?: string;
}

export default function SlickPage(prop: SlickPageProps): JSX.Element {
    const matches = useMediaQuery('(min-width:600px)');
    console.log(prop.backgroundColour)
    return (
        <Stack spacing={8} style={{backgroundColor: prop.backgroundColour || '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Link to={prop.link || ''}>
                <img src={prop.img} alt={prop.name} style={matches ? {height: '100vh'} : {width: '100vw'}} ></img>
            </Link>
        </Stack>
        // <div style={{}}>
            
        // </div>
    );
}
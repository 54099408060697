import { Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface Props {
  message?: string;
}

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  height: '70vh'
}

export default function Success(prop: Props): JSX.Element {
  const theme = useTheme()
  const matches = useMediaQuery('(min-width:600px)');

  return <Paper elevation={0}>
    <Stack style={style}>
      <CheckCircleOutlineIcon style={{height: 130, width: 130}} color="success" fontSize="large"/>
      <Typography style={{padding: 20}} variant="h4" align='center'>{prop.message || "Success!"}</Typography>
    </Stack>
    </Paper>
}
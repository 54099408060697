import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navigation from './components/Navigation';
import Logo from './assets/images/logo.svg'
import axios from 'axios';
import Routes from './routes';
import mediaQuery from 'css-mediaquery';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breadcrumbs, Link, Paper, Stack, Typography } from '@mui/material';
import { NavigationItems } from './constants';
// import './App.css';

const ssrMatchMedia = (query: string) => ({
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: 800,
  }),
});
const darkTheme = createTheme({
  palette: {
    text: {
      disabled: "#e56b6f",
    },
    primary: { 
      light: "#BC7382",
      main: "#b56576",
      dark: "#6d597A",
    },
    secondary: {
      light: "#ECB496",
      main: "#eAAC8B",
      dark: "#D59C7E"
    },
    background: {
      default: "#2f2f2f"
    }
    //"darker": '#355070',
  }
})
const theme = createTheme({
  palette: {
    text: {
      disabled: "#e56b6f",
    },
    primary: { 
      light: "#ECB496",
      main: "#eAAC8B",
      dark: "#D59C7E"
    },
    secondary: {
      light: "#BC7382",
      main: "#b56576",
      dark: "#6d597A",
    },
    background: {
      default: "#f2f2f2",
      // paper: "#2f2f2f",
    }
    //"darker": '#355070',
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    // Change the default options of useMediaQuery
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia,
      },
    },
  },
})
 // TODO: add dev dependency: @babel/plugin-proposal-private-property-in-object
function App() {
  return (
    <ThemeProvider theme={theme}>
        <Paper elevation={3}>
          <Stack style={{ alignContent: "center", justifyContent: "center", alignItems: "center", display: "flex"}}>
            <img src={Logo} alt="React Logo" height="90" width="90" style={{display: 'flex'}}/>
            <Breadcrumbs aria-label="breadcrumb" style={{margin: 10}}>
              {NavigationItems.map((item, i) => <Link key={i} underline="hover" color="inherit" href={item.route}>
                {item.name}
              </Link>)}
            </Breadcrumbs>
          </Stack>
        </Paper>
      <Routes/>
    </ThemeProvider>
  );
}

export default App;

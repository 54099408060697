import { Button, Divider, Grid, Link, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate, useParams } from 'react-router-dom'
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Red from '../../assets/images/socialmedia/red.png';
import Wechat from '../../assets/images/socialmedia/wechat.png';
import { useQuery } from "../../helpers/hooks";

export default function Contact(): JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const matches = useMediaQuery('(min-width:600px)');

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '70vh',
    padding: 20,
  }

  const grid = {
    display: 'flex',
    justifyContent: matches ? 'flex-start' : 'center',
    alignItems: 'center',
    alignContent: 'center',
  }

  const input = {
    width: matches? '70vw' : '85vw',
  }

  const iconText = {
    paddingLeft: 10,
  }

  console.log('contact query: ', query)

  return <Paper elevation={0}>
    <Typography style={{padding: 20}} variant="h4" align='center'>Contact Us</Typography>
    <Typography style={{padding: 20}} variant="body1" align='center'>You can contact us through our social media and email: </Typography>
    <Grid container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center">
    <Grid container sx={{width: matches? "70%" : '100%'}} style={{paddingLeft: 20, paddingRight: 20,
        ...grid}} spacing={3}>
      <Grid item xs={12} md={3} style={grid}>
      <InstagramIcon/><Typography variant="body1">@not.evena</Typography>
      </Grid>
      <Grid item xs={12} md={3} style={grid}>
      <img src={Red} width="40"/><Typography variant="body1">@Notevena</Typography>
      </Grid>
      <Grid item xs={12} md={3} style={grid}>
      <img src={Wechat} width="30"/><Typography variant="body1">@notevenastudio</Typography>
      </Grid>
      <Grid item xs={12} md={3} style={grid}>
      <MailOutlineIcon/><Typography variant="body1"><Link href="mailto:enquiry@notevenastudio.com">enquiry@notevenastudio.com</Link></Typography>
      </Grid>
    </Grid>
    </Grid>
    
    {/* <Typography style={{padding: 20}} variant="body1" align='center'>Or alternatively you can send us your enquiry through here: </Typography>

    <Divider/>
      <Paper elevation={0} style={{...style, marginTop: 30}}>
        <Stack spacing={5}>
          <Stack style={input}>
          <Typography>First Name</Typography>
          <TextField id="standard-basic"  variant="standard" required/>
          </Stack>
          
          <Stack style={input}>
          <Typography>Last Name</Typography>
          <TextField id="standard-basic"  variant="standard" required/>
          </Stack>

          <Stack style={input}>
          <Typography>Email</Typography>
          <TextField id="standard-basic"  variant="standard" required/>
          </Stack>

          <Stack style={input}>
          <Typography>{`Phone(Optional)`}</Typography>
          <TextField id="standard-basic"  variant="standard" />
          </Stack>

          <Stack style={input}>
          <Typography>Enquiry title</Typography>
          <TextField id="standard-basic"  variant="standard" value={query.get('messageTitle') ? query.get('messageTitle') : ''} />
          </Stack>

          <Stack style={input}>
          <Typography>Enquiry details</Typography>
          <TextField id="standard-multiline-static" multiline variant="standard" rows={4}/>
          </Stack>

          <Button variant="outlined" onClick={() => navigate('/success')}>Submit</Button>
        </Stack>
      </Paper> */}
    </Paper>
}
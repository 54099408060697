import { CardMedia, ImageList, ImageListItem, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ProductList } from "./constants";
import ItemSummary from "../../components/ItemSummary";
import { useParams } from "react-router-dom";
import ContactButton from "../../components/ContactButton";
import { relative } from "path";

export default function Products(): JSX.Element {
  const theme = useTheme()
  const matches = useMediaQuery('(min-width:600px)');
  const params = useParams();

  console.log('params: ', params)

  if (params.id) {
    const product = ProductList.find(product => product.id === parseInt(params.id || '0'))

    return <Paper elevation={0} style={{backgroundColor: '#2f2f2f', height: '100vh'}} >
      <Typography style={{padding: 20}} color="white" variant="h4" align='center'>Products Details</Typography>
      <ImageList variant="quilted"
        cols={matches ? 4 : 1}
        gap={8}
        style={matches ?{
          margin: "auto",
          paddingLeft: "20%",
          paddingRight: "20%",
        } : {
          margin: "auto",
          padding: "10px",
        }}>
          <ImageListItem cols={matches ? 2 : 1 } rows={matches ? 2 : 1} style={{padding: 40}}>
          <CardMedia
            height={matches ? 'auto' : 250}
            component="img"
            image={product?.img}
          />
          </ImageListItem>
          <Stack style={matches ? {padding: 40} : {paddingLeft: 40, paddingRight: 40}}>
            <Typography variant="h6" color="white">{product?.title}</Typography>
            <Typography variant="body1" color="white" >{product?.details}</Typography>
            <ContactButton style={{marginTop: 20}} messageTitle={"Enquiry regarding " + product?.title} />
          </Stack>


      {/* <Card elevation={0} style={{margin: 'auto', display: 'flex', backgroundColor: theme.palette.background.default, width: '100%', height: '100%'}}></Card> */}

      </ImageList>
    </Paper>
  } else {
    return <Paper elevation={0} style={{backgroundColor: '#2f2f2f'}}>
    <Typography style={{padding: 20}} color="white" variant="h4" align='center'>Products List</Typography>
    <ImageList variant="quilted"
      cols={matches ? 4 : 1}
      gap={8}
      style={matches ?{
        margin: "auto",
        paddingLeft: "20%",
        paddingRight: "20%",
      } : {
        margin: "auto",
        padding: "10px",
      }}>
    {ProductList.map((product) => <ItemSummary key={product.id} id={product.id} title={product.title} details={product.details} type={product.type} img={product.img}/>)}
  </ImageList>
    </Paper>
  }
}
import React from 'react';
import { NavigationItem } from "../types";
import Home from "../screens/Home";
import Products from '../screens/Products';
import Contact from '../screens/Contact';
import Success from '../screens/Success';
import About from '../screens/About';
import Animanga from '../assets/images/animangaNew.png';

export const NavigationItems: NavigationItem[] = [
  {
    name: "Home",
    route: "/",
    component: <Home />,
  },
  {
    name: "Products",
    route: "/products",
    component: <Products/>,
  },
  {
    name: "About",
    route: "/about",
    component: <Home/>
  },
  {
    name: "Contact",
    route: "/contact",
    component: <Home/>
  },
  // {
  //   name: "Make your own",
  //   route: "/diyeditor",
  //   component: <DIYEditor />
  //   // easterEgg: 
  // },
  // {
  //   name: "Inquiries",
  //   route: "/contact",
  //   component: <Contact />
  // }
]

export const RouteItems: any[] = [
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/home",
    element: <Home/>,
  },
  {
    path: "/about",
    element: <About/>,
  },
  {
    path: "/contact",
    element: <Contact/>,
  },
  {
    path: "/products",
    element: <Products/>,
  },
  {
    path: "/products/:id",
    element: <Products/>,
  },
  {
    path: "/success",
    element: <Success/>,
  }
]

export const SlickNav: any[] = [
  {
    name: "animanga",
    img: Animanga,
    link: "https://animaga.com.au/",
    backgroundColour: 'linear-gradient(to bottom right, #6618cd, #2871fa)'
  }
]
import React, { useState } from 'react';
import List from '@mui/material/List';
import { ListItem, ListItemText, Button, useTheme, Card, CardMedia, CardActionArea, CardContent, Typography, CardActions, ImageListItem, useMediaQuery } from '@mui/material';
import { redirect, useNavigate } from 'react-router-dom';

export interface ItemSummaryProps {
  title: string;
  details: string;
  id: number;
  type: string;
  img: any;
  // onSwitchChange: (previousVal: boolean | undefined, val?: boolean) => void;
}


export default function ItemSummary(prop: ItemSummaryProps): JSX.Element {
  const theme = useTheme()
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();

  function handleItem() {
    navigate('/products/' + prop.id)
  }

  return (
    <ImageListItem key={prop.id} cols={matches ? prop.id % 3 === 0 ? 2 : 1 : 1} rows={matches ? prop.id % 3 === 0 ? 2 : 1 : 1} onClick={handleItem}>
      <Card elevation={0} style={{margin: 'auto', display: 'flex', backgroundColor: theme.palette.background.default, width: '100%', height: '100%'}}>
    <CardActionArea>
      <CardMedia
        height={matches ? 350 : 250}
        component="img"
        image={prop.img}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {prop.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {prop.details}
        </Typography>
      </CardContent>
    </CardActionArea>
    {/* <CardActions>
      <Button size="small" color="primary">
        View
      </Button>
    </CardActions> */}
  </Card>
    </ImageListItem>
  );
}

import ItemSummary from "../../components/ItemSummary"
import TShirt from "../../assets/images/product1.png"
import Pin from "../../assets/images/pin.png"
import CanvasBag from "../../assets/images/canvas-bag.jpeg"
import Hoodie from "../../assets/images/hoodie.webp"
import Giftbox from "../../assets/images/giftbox.png"
import Magnet from "../../assets/images/magnet.jpg"

interface Product {
  id: number
  title: string
  details: string
  type: string
  img: any
}

//TODO: move to db
export const ProductList: Product[] = [
  {
    id: 1,
    title: "T-shirt Unisex",
    details: "From $23",
    type: "clothes",
    img: TShirt
  },
  {
    id: 2,
    title: "Pin",
    details: "From $2",
    type: "accessories",
    img: Pin
  },
  {
    id: 3,
    title: "Canvas Bag",
    details: "From $8",
    type: "bag",
    img: CanvasBag
  },
  {
    id: 4,
    title: "Magnet",
    details: "From $8",
    type: "accessories",
    img: Magnet
  },
  {
    id: 5,
    title: "Hoodie",
    details: "From $25",
    type: "clothes",
    img: Hoodie
  },
  {
    id: 6,
    title: "Gift pack",
    details: "Set of merchs with gift pack. From $60",
    type: "clothes",
    img: Giftbox
  },
]

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Avatar, Button, Stack, useMediaQuery } from '@mui/material';
import Design from '../../assets/icons/design.svg';
import Write from '../../assets/icons/write.svg';
import Receive from '../../assets/icons/receive.svg';
import BackgroundOne from '../../assets/images/background/shirt.jpg';
import BackgroundTwo from '../../assets/images/background/sale.jpg';
import BackgroundThree from '../../assets/images/background/laptop.jpg';
import { useTheme } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ContactButton from '../../components/ContactButton';
import Slider from "react-slick";
import { SlickNav } from '../../constants';
import SlickPage from '../../components/SlickPage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home(): JSX.Element {
  const theme = useTheme()
  const navigate = useNavigate()
  const matches = useMediaQuery('(min-width:600px)');

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: matches ? '100vh' : '100vh',
    backdropFilter: `blur(30px)`
  }
  
  const typography = {
    padding: '15%',
  }

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    adaptiveHeight: true
  };

  return (
    <Paper elevation={0}>
      <Typography style={{padding: 20}} variant="h4" align='center'>Upcoming Events</Typography>

      {/* <div className="slider-container"> */}
      
      {/* </div> */}
      <Stack spacing={8}>
      <Slider {...settings}>
        {SlickNav.map((s, i) => <SlickPage key={i} {...s}/>)}
      </Slider>
      </Stack>
      <Stack spacing={8} style={{backgroundImage: `url(${BackgroundOne}`}}>
      
      <Stack style={{...style}}>
        <Avatar
        sx={{ bgcolor: theme.palette.primary.light, width: 100, height: 100 }}>
          <img src={Write} alt="Write" width="70" height="70"/>
        </Avatar>
        <Typography style={typography} align='center' color="black" variant="h5">Send us your memory and your requirement!</Typography>
        <ContactButton/>
      </Stack>
      </Stack>
      <Stack spacing={8} style={{backgroundColor: theme.palette.secondary.main}}>
      <Stack style={{...style}}>
        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 100, height: 100 }}>
          <img src={Design} alt="Design" width="70" height="70"/>
        </Avatar>
        <Typography style={typography} align='center' color="black" variant="h5">We will connect you to our best designers! Pick your style, create your own merch!</Typography>
        <ContactButton/>
      </Stack>
      </Stack>
      <Stack spacing={8} style={{backgroundImage: `url(${BackgroundTwo}`}}>
      <Stack style={{...style}}>
        <Avatar sx={{ bgcolor: theme.palette.primary.dark, width: 100, height: 100 }}>
          <img src={Receive} alt="Receive" width="70" height="70"/>
        </Avatar>
        <Typography style={typography} align='center' color="black" variant="h5">Wait for your merch to show on your door step in no time!</Typography>
        <ContactButton/>
      </Stack>
      </Stack>
    </Paper>
  )
}
import { Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import Grid from '@mui/material/Grid'; 
import Background from '../../assets/images/background/shirt.jpg'

export default function About(): JSX.Element {
  const theme = useTheme()
  const matches = useMediaQuery('(min-width:600px)');

   return <Grid container style={{height: '100%'}}>
    <Grid item xs={6}>
    <Paper elevation={0} style={{display: matches ? "" : "none", backgroundImage: `url(${Background})`, height: '100%', backgroundSize: '150%'}} />
    </Grid>
    <Grid item xs={matches ? 6 : 12}>
      <Stack height={'100%'}>
    <Typography style={{padding: 20}} color="black" variant="h4" align='center'>About Us</Typography>
    <Typography style={{padding: 20}} color="black" variant="body2" align='center'>
    Not Evena Studio is based in Melbourne, Victoria and was funded in 2022 , with the aim of providing the best quality of products to our customers. The studio is dedicated to complete small orders of goods with no minimum order number requirement, from clothing to accessories. As long as you bring your idea to us, we will make your dream come true.
    </Typography>
    <Typography style={{padding: 20}} color="black" variant="h5" align='center'>Brand feature</Typography>

    <Typography style={{padding: 20}} color="black" variant="body2" align='center'>
    Low MOQ - Minimum order of one to lift your pressure of stocking up.
    </Typography>

    <Typography style={{padding: 20}} color="black" variant="body2" align='center'>
    Short ETA - Melbourne based production to reduce waiting time.
    </Typography>

    <Typography style={{padding: 20}} color="black" variant="body2" align='center'>
    Divers form - Find the most suitable carrier for your artistic expression. 
    </Typography>

    <Typography style={{padding: 20}} color="black" variant="body2" align='center'>
    Affordable - Meet your budget in everyway.
    </Typography>

    <Typography style={{padding: 20}} color="black" variant="body2" align='center'>
    Promotion - Exclusive promotion opportunity to expand your audience range when you purchase with us.       
    </Typography>
    </Stack>
    </Grid>

    </Grid>
}
import * as React from 'react';
import { Button } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface Props {
  messageTitle?: string;
  style?: any
}

export default function ContactButton(props: Props): JSX.Element {
  const navigate = useNavigate()

  function handleContact() {
    const route = '/contact' + (props.messageTitle ? '?messageTitle=' + props.messageTitle : '')
    console.log('route: ', route)
    navigate(route)
  }

  return (
    <Button variant="contained" style={props.style} endIcon={<Send />} onClick={handleContact}>
          Contact us
    </Button>
  )
}